export default [
  {
    path: "/providers",
    name: "providers",
    component: () => import("../views/providers/Index.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Providers",
    },
  },
  {
    path: "/providers/create",
    name: "providers.add",
    component: () => import("../views/providers/Create.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Create Provider",
    },
  },
  {
    path: "/providers/:id",
    component: () => import("../views/providers/profile/Index.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        component: () => import("../views/providers/Profile.vue"),
        children: [
          {
            path: "",
            name: "providers.basic",
            component: () => import("../views/providers/profile/Basic.vue"),
          },
          {
            path: "/providers/:id/address",
            name: "providers.address",
            component: () =>
              import("../views/providers/profile/address/Index.vue"),
          },
          {
            path: "/providers/:id/phones",
            name: "providers.phones",
            component: () =>
              import("../views/providers/profile/phones/Index.vue"),
          },
          {
            path: "/providers/:id/emails",
            name: "providers.emails",
            component: () =>
              import("../views/providers/profile/emails/Index.vue"),
          },

          {
            path: "classes",
            name: "providers.classes",
            component: () =>
              import("../views/providers/profile/classifications/Index.vue"),
          },
        ],
      },
      {
        path: "profession",
        name: "providers.profession",
        component: () =>
          import("../views/providers/profile/profession/Index.vue"),
        meta: {
          title: "Profession",
        },
      },
      {
        path: "clients",
        name: "providers.patients",
        component: () =>
          import("../views/providers/profile/patients/Index.vue"),
      },
      {
        path: "contacts",
        name: "providers.contacts",
        component: () =>
          import("../views/providers/profile/contacts/Index.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "payroll",
        name: "providers.payroll",
        component: () => import("../views/providers/profile/payroll/Index.vue"),
      },
      {
        path: "documents",
        name: "providers.documents",
        component: () =>
          import("../views/providers/profile/documents/Index.vue"),
      },
      {
        path: "timeline",
        name: "providers.timeline",
        component: () =>
          import("../views/providers/profile/timeline/Index.vue"),
      },
      {
        path: "c3-integration",
        name: "provider.c3.integration",
        component: () => import("../views/myProfile/C3Integration.vue"),
      },
      {
        path: "availability",
        name: "providers.availability",
        component: () => import("../views/providers/profile/Availability.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "shared-recordings",
        name: "providers.shared-recordings",
        component: () =>
          import("../views/providers/profile/SharedRecordings.vue"),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: "/providers/:id/phones/create",
    name: "providers.phones.create",
    component: () => import("../views/providers/profile/phones/Create.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/providers/:id/address/create",
    name: "providers.address.create",
    component: () => import("../views/providers/profile/address/Create.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/providers/:id/emails/create",
    name: "providers.emails.create",
    component: () => import("../views/providers/profile/emails/Create.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/providers/:id/profession/create",
    name: "providers.profession.create",
    component: () => import("../views/providers/profile/profession/Create.vue"),
    meta: {
      title: "Profession",
      auth: true,
    },
  },
  {
    path: "/providers/:id/classes/create",
    name: "providers.classes.create",
    component: () =>
      import("../views/providers/profile/classifications/Create.vue"),
    meta: {
      auth: true,
    },
  },
];
