export default [
  {
    path: "/alert/my-alerts",
    name: "my-alerts",
    component: () => import("../views/alert/my-alerts/Index.vue"),
    meta: {
      auth: true,
      alert: true,
      title: "My Alerts",
    },
  },

  {
    path: "/alert/provider",
    name: "provider-alert",
    component: () => import("../views/alert/provider/Index.vue"),
    meta: {
      auth: true,
      alert: true,
      title: "Provider Alerts",
    },
  },
  {
    path: "/alert/client",
    name: "client-alert",
    component: () => import("../views/alert/client/Index.vue"),
    meta: {
      auth: true,
      alert: true,
      title: "Client Alerts",
    },
  },
  {
    path: "/alert/calendar",
    name: "calendar-view",
    component: () => import("../views/alert/calendar/Index.vue"),
    meta: {
      auth: true,
      alert: true,
      title: "Calendar View",
    },
  },
];
