export default [
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/myProfile/Profile.vue"),
    meta: {
      auth: true,
      title: "profile",
    },
  },
  {
    path: "/profile/c3-integration",
    name: "profile.c3.integration",
    component: () => import("../views/myProfile/C3Integration.vue"),
    meta: {
      auth: true,
      title: "C3 App",
    },
  },
];
