import Vue from "vue";
import VueRouter from "vue-router";
import qs from "qs";

import base from "./base";
import patients from "./patients";
import admin from "./admin";
import providers from "./providers";
import staticPages from "./static";
import alerts from "./alerts";
import myProfile from "./myProfile";
import store from "../store";

Vue.use(VueRouter);
const routes = [
  ...base,
  ...staticPages,
  ...patients,
  ...admin,
  ...providers,
  ...myProfile,
  ...alerts,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query, { encode: false });

    return result ? "?" + result : "";
  },
});

router.beforeEach((to, from, next) => {
  let title = store.state["appConfig"].title || "C3 CRM";

  if (to.meta.title) {
    title = title + " | " + to.meta.title;
  }
  document.title = title;

  if (to.matched.some((record) => record.meta.auth)) {
    if (!localStorage.getItem("ARSS_TOKEN")) {
      next({ name: "login" });
    } else if (
      localStorage.getItem("ARSS_TOKEN") &&
      !localStorage.getItem("USER_TYPE") &&
      !store.state["auth"].authenticated
    ) {
      localStorage.removeItem("ARSS_TOKEN");
      localStorage.removeItem("USER_TYPE");
      localStorage.removeItem("C3_LOGIN");
      next({ name: "login" });
    } else {
      if (to.matched.some((record) => record.meta.admin)) {
        if (
          to.matched.some((record) => record.meta.noManager) &&
          localStorage.getItem("USER_TYPE") === "Manager"
        ) {
          next({ name: "dashboard" });
        } else if (
          localStorage.getItem("USER_TYPE") !== "Admin" &&
          localStorage.getItem("USER_TYPE") !== "Manager"
        ) {
          next({ name: "dashboard" });
        } else {
          next();
        }
      } else if (to.matched.some((record) => record.meta.provider)) {
        if (localStorage.getItem("USER_TYPE") !== "Provider") {
          next({ name: "dashboard" });
        } else {
          next();
        }
      } else if (
        to.matched.some((record) => record.meta.noClient) &&
        localStorage.getItem("USER_TYPE") === "Patient"
      ) {
        next({ name: "dashboard" });
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("ARSS_TOKEN")) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
